import {
  createRouter,
  createWebHashHistory
} from 'vue-router'

const routes = [{
  path: '/login',
  name: 'Login',
  component: () => import('@/views/login/index')
}, {
  path: '/',
  name: 'Root',
  component: () => import('@/views/layouts/index'),
  children: [{
    path: '/',
    name: 'Home',
    component: () => import('@/views/index/index')
  }, {
    path: '/info',
    name: 'Info',
    component: () => import('@/views/index/info')
  }, {
    path: '/authgroup',
    name: 'Authgroup',
    component: () => import('@/views/sys/authgroup')
  }, {
    path: '/authmain',
    name: 'Authmain',
    component: () => import('@/views/sys/authmain')
  }, {
    path: '/sys',
    name: 'Sys',
    component: () => import('@/views/sys/sys')
  }, {
    path: '/ofct',
    name: 'Ofct',
    component: () => import('@/views/sys/ofct')
  }, {
    path: '/diag',
    name: 'Diag',
    component: () => import('@/views/sys/diag')
  }, {
    path: '/dept',
    name: 'Dept',
    component: () => import('@/views/sys/dept')
  }, {
    path: '/hp',
    name: 'Hp',
    component: () => import('@/views/sys/hp')
  }, {
    path: '/ofdr',
    name: 'Ofdr',
    component: () => import('@/views/user/ofdr')
  }, {
    path: '/indr',
    name: 'Indr',
    component: () => import('@/views/user/indr')
  }, {
    path: '/dr',
    name: 'Dr',
    component: () => import('@/views/user/dr')
  }, {
    path: '/ur',
    name: 'Ur',
    component: () => import('@/views/user/ur')
  }, {
    path: '/chkl',
    name: 'Chkl',
    component: () => import('@/views/index/chkl')
  }]
}]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router