/**
 * 系统配置
 */
module.exports = {
    // 标题名称
    web_title: '银川智治互联网医院管理端',
    // token名称
    tokenName: 'zz-main-token',
    // token在localStorage、sessionStorage、cookie存储的key的名称
    tokenTableName: 'zz-main-token',
    // token存储位置localStorage sessionStorage cookie
    storage: 'localStorage',
    // 不经过token校验的路由，白名单路由建议配置到与login页面同级，如果需要放行带传参的页面，请使用query传参，配置时只配置path即可
    routesWhiteList: ['/login', '/404', '/403'],
}