import {
    createApp
} from 'vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from './App.vue'
import store from './store'
import router from './router'

import {
    tokenTableName,
    storage,
    routesWhiteList
} from '@/config'

const app = createApp(App)

// 检测登录
router.beforeEach((to, from, next) => {
    if (routesWhiteList.indexOf(to.path) == -1) {
        var token = null;
        if (storage === 'localStorage') {
            token = localStorage.getItem(tokenTableName)
        } else if (storage === 'sessionStorage') {
            token = sessionStorage.getItem(tokenTableName)
        } else {
            token = localStorage.getItem(tokenTableName)
        }
        if (token == null) {
            next('/login')
        } else {
            next()
        }
    } else {
        next();
    }
    // next()
})

app.use(ElementPlus, {
    locale: zhCn
})

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(router).use(store).mount('#app')