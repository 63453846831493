/**
 * 网络配置
 */
module.exports = {
    // 默认接口地址
    hostUrl: 'https://api.yczzyy.com/',
    // hostUrl: 'https://zz.acnew.cn/',
    baseURL: 'https://api.yczzyy.com/main',
    // baseURL: 'https://zz.acnew.cn/main',
    // 配后端数据的接收方式application/json;charset=UTF-8 或 application/x-www-form-urlencoded;charset=UTF-8
    contentType: 'application/x-www-form-urlencoded;charset=UTF-8',
    requestTimeout: 10000
}